<template>
    <budget-wrapper>
        <template v-slot:child>
            <div class="w-90 center">
                <form class="entity-form  center" @submit.prevent="onSubmit" enctype="multipart/form-data">
                    <div class="">
                        <div class="pv2" style="border-width: 2px">
                            <div class="pt2 w-100 pr2-l">
                                <div class="pv2 font-w1">
                                    <label for="budgetLine">Budget Line</label>
                                </div>
                                <select class="w-100" required type="text" id="budgetLine">
                                    <option name="Asset">Asset</option>
                                    <option name="Liability">Liability</option>
                                    <option name="Income">Income</option>
                                    <option name="Expenses">Expenses</option>
                                    <option name="Equity">Equity</option>
                                </select>
                            </div>
                            <div class="flex w-70" style="gap: 32px">
                                <div class="pt2 w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="startPeriod">Start Period</label>
                                    </div>
                                    <input class="w-100" required type="date" id="startPeriod" />
                                </div>
                                <div class="pt2 w-100 pr2-l">
                                    <div class="pv2 font-w1">
                                        <label for="endPeriod">End Period</label>
                                    </div>
                                    <input class="w-100" required type="date" id="endPeriod" />
                                </div>
                            </div>
                            <div class="pt4 flex" style="justify-content: flex-end">
                                <button>Update</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="margin24 pt4">
                    <div class="overflow-auto">
                        <table class="f6 w-100" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="py-8 billPad manageHeader pl2 fw6 tl p9 pr3 w-30">Line Item Budget</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3 w-30">Previous Actual</th>
                                    <th class="py-8 billPad manageHeader pl3 fw6 tl p9 pr3 w-30">Budget</th>
                                </tr>
                            </thead>
                            <tbody class="lh-copy">
                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plAsset')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plAsset ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Assets</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Equipment
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Trade Payable
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Accrued Expenses
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Income Tax Payable
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                                <tr v-if="plAsset" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Borrowings
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plLiability')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plLiability ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Liability</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                </tr>
                                <tr v-if="plLiability" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plIncome')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plIncome ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Income</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                </tr>
                                <tr v-if="plIncome" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plExpenses')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plExpenses ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Expenses</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                </tr>
                                <tr v-if="plExpenses" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>

                                <tr class="font-w1 box-border-bottom">
                                    <td
                                        @click="toggleSubTable('plEquity')"
                                        class="pv3 bb b--black-20 tl font-w1 flex items-center"
                                        style="padding-right: 4rem; cursor: pointer"
                                    >
                                        <img
                                            id=""
                                            class="showLayers"
                                            :class="plEquity ? 'rotate' : ''"
                                            :src="require('@/assets/images/arrow.png')"
                                            width="5"
                                        />
                                        <div class="ml2 text-capitalize" style="font-size: 13px">Equity</div>
                                    </td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                    <td class="pv3 pl3 pr4 bb b--black-20 tl items-center">0.00</td>
                                </tr>
                                <tr v-if="plEquity" style="padding-top: 5px; padding-bottom: 25px">
                                    <td
                                        class="pl3 bb b--black-20 font13 text-capitalize"
                                        style="padding-top: 15px; padding-bottom: 15px"
                                    >
                                        Cash on hand
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                    <td class="pl3 bb b--black-20 textRight pr4" style="padding-top: 15px; padding-bottom: 15px">
                                        0.00
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="pt4 flex gap8" style="justify-content: flex-end">
                            <button class="flex gap8"><span>+</span><span>Save as Draft</span></button>
                            <button class="flex gap8"><span>+</span><span>Update</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </budget-wrapper>
</template>
<script>
import { ref } from 'vue'
import AppWrapper from '@/layout/AppWrapper.vue'
import BudgetWrapper from './widgets/BudgetWrapper.vue'
export default {
    name: 'UpdatePnLBudget',
    components: { AppWrapper, BudgetWrapper },
    setup() {
        const plAsset = ref(false)
        const plLiability = ref(false)
        const plIncome = ref(false)
        const plExpenses = ref(false)
        const plEquity = ref(false)

        const toggleSubTable = (name) => {
            if (name === 'plAsset') {
                plAsset.value = !plAsset.value
            }
            if (name === 'plLiability') {
                plLiability.value = !plLiability.value
            }
            if (name === 'plIncome') {
                plIncome.value = !plIncome.value
            }
            if (name === 'plExpenses') {
                plExpenses.value = !plExpenses.value
            }
            if (name === 'plEquity') {
                plEquity.value = !plEquity.value
            }
        }
        return {
            plAsset,
            plLiability,
            plIncome,
            plExpenses,
            plEquity,
            toggleSubTable,
        }
    },
}
</script>
<style scoped>
.w-25 {
    width: 25%;
}
.rotate {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
</style>
