<template>
    <div>
        <div class="w-90 center pv4-l pv3 mini-spacing">
            <div class="pb3 b" v-if="isCreatePnLBudget">
                <router-link :to="{name: 'PnLBudget'}">&lt; Back</router-link>
            </div>
            <p class="pb2 f3 b">
                Budget
                <strong
                    ><font-awesome-icon
                        icon="fa-solid fa-question-circle fa-2xs"
                        class="text-muted clickTooltipIcon"
                        @click="toggleTooltip = true"
                        style="cursor: pointer"
                /></strong>
            </p>

            <div class="dashboard-inquiry-modal" v-if="toggleTooltip">
                Use this to track money you or others have invested into the business. For example, when you first start a
                business you usually invest start-up money into it.
            </div>
        </div>
        <!-- budget -->

        <!-- menu -->
        <div class="w-90 center">
            <div class="flex scrollmenu box-border-bottom">
                <router-link
                    active-class="active-menu"
                    :class="{ 'active-menu': $route.fullPath.startsWith('/budget/line-item') }"
                    :to="{ name: 'LineBudget' }"
                >
                    <div class="mr3">Line Item Budget</div>
                </router-link>
                <router-link
                    active-class="active-menu"
                    :class="{ 'active-menu': $route.fullPath.startsWith('/budget/profit-loss') }"
                    :to="{ name: 'PnLBudget' }"
                >
                    <div class="mr3">P&L Budget</div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    name: 'BudgetHeader',
    setup() {
        const route = useRoute()
        const router = useRouter()
        const toggleTooltip = ref(false)
        const closeTooltip = () => {
            toggleTooltip.value = false
        }

        const isCreatePnLBudget = ref(route.path === '/budget/profit-loss/create')

        watch(() => {
            isCreatePnLBudget.value = route.path === '/budget/profit-loss/create'
        })
        window.addEventListener('click', (event) => {
            const tooltipIcon = document.querySelector('.clickTooltipIcon')

            if (!(event.target.closest('.clickTooltipIcon') || event.target === tooltipIcon) && toggleTooltip.value === true) {
                closeTooltip()
            }
        })
        return {
            toggleTooltip,
            closeTooltip,
            isCreatePnLBudget,
        }
    },
}
</script>
<style scoped></style>
