<template>
    <app-wrapper>
        <template v-slot:child>
            <BudgetHeader />

            <slot name="child"> </slot>
        </template>
    </app-wrapper>
</template>

<script>
import AppWrapper from '@/layout/AppWrapper'
import BudgetHeader from './BudgetHeader';

export default {
    name: 'BudgetWrapper',
    components: { AppWrapper, BudgetHeader },
}
</script>

<style scoped></style>